












import { Component, Vue } from "vue-property-decorator";

@Component
export default class AuthShell extends Vue {
  protected busy = false;
}
