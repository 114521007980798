







































import AuthRequest from "@/models/Requests/AuthRequest";
import AuthResponse from "@/models/Responses/AuthResponse";
import AuthService from "@/services/AuthService";
import { Component } from "vue-property-decorator";
import AuthShell from "./AuthShell.vue";

@Component
export default class Login extends AuthShell {
  private pwdVisible = false;
  private auth: AuthRequest = {
    email: "",
    password: "",
  };

  private async submit(): Promise<void> {
    this.busy = true;
    try {
      await AuthService.login(this.auth);
      const authUser = await this.$store.dispatch("auth/getAuthUser");
      if (authUser) {
        this.busy = false;
        this.$router.push("/");
      }
    } catch (error) {
      this.busy = false;
      this.$toast.error(
        `Er is iets fout gegeaan (${(error as AuthResponse).message})`
      );
    }
  }
}
